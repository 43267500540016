
import { Component, Vue } from "vue-property-decorator";
import { DispatchService } from "@/services/dispatch-service";
import { ReceiptService } from "@/services/receipt-service";
import { TruckDriverService } from "@/services/truck-driver-service";
import { IDispatch } from "@/types/Dispatch";
import { IReceipt } from "@/types/Receipt";
import newReceiptModal from "@/components/ReceiptModal.vue";
import newInstructedReceiptModal from "@/components/InstructedReceiptModal.vue";
import viewDispatchModal from "@/components/ViewDispatchModal.vue";
import confirmDeleteModal from "@/components/ConfirmDeleteModal.vue";
import editDispatchModal from "@/components/EditDispatchModal.vue";
import { ITruckDriver } from "@/types/TruckDriver";
import { Defaults } from "@/helpers/Defaults";
import { IInstructedReceipt } from "@/types/InstructedReceipt";
import { IDispatchedCommodity } from "@/types/DispatchedCommodity";
import { IInputCommodities } from "@/types/InputCommodities";
import { InstructedReceiptService } from "@/services/instructed-receipt-services";
import { IResponseData } from "@/types/ResponseData";
import { ApiService } from "@/services/api-services";
@Component({
  name: "Dispatch",
  components: {
    viewDispatchModal,
    newReceiptModal,
    newInstructedReceiptModal,
    confirmDeleteModal,
    editDispatchModal,
  },
})
export default class Dispatch extends Vue {
  loading = true;
  dispatchSkeleton = true;
  iLoading = true;
  iDispatchSkeleton = true;
  receiptOverlay = { show: false };
  iReceiptOverlay = { show: false };
  confirmDelete = { showOverlay: false };
  edit = { showOverlay: false }; // this is for dispatches
  userString = localStorage.getItem("user");
  isAdmin = false;
  defaults = new Defaults();
  apiService = new ApiService();
  dispatchService = new DispatchService();
  receiptService = new ReceiptService();
  iReceiptService = new InstructedReceiptService();
  driverService = new TruckDriverService();
  dispatchResponseData: IResponseData = this.defaults.responseData;
  iDispatchResponseData: IResponseData = this.defaults.responseData;
  unitResponseData: IResponseData = this.defaults.responseData;
  userResponseData: IResponseData = this.defaults.responseData;
  lPageNumber = 1;
  iPageNumber = 1;
  loadingPlan = {};
  receipt: IReceipt = this.defaults.receipt;
  iReceipt: IInstructedReceipt = this.defaults.instructedReceipt;
  drivers: ITruckDriver[] = [];
  receivedIds: number[] = [];
  async initialize(): Promise<void> {
    let requestParams = this.defaults.requestParams;
    requestParams.isPaged = false;
    let response = await this.apiService.getAll("unit", requestParams);
    if (!response.isSuccess && response.status == 401)
      this.visitRoute("/logout");
    this.unitResponseData = response.data;
    response = await this.apiService.getAll("user", requestParams);
    this.userResponseData = response.data;
    requestParams.isPaged = true;
    requestParams.includes =
      '["Driver","LoadingPlan","LoadingPlan.Transporter","LoadingPlan.Warehouse","LoadingPlan.District","LoadingPlan.Commodity"]';
    response = await this.apiService.getAll("dispatch", requestParams);
    if (!response.isSuccess && response.status == 401)
      this.visitRoute("/logout");
    requestParams.includes =
      '["Driver", "Dispatcher","DispatchedCommodities","DispatchedCommodities.Commodity","Instruction","Instruction.Warehouse","Instruction.District"]';
    this.dispatchResponseData = response.data ?? this.defaults.responseData;
    response = await this.apiService.getAll(
      "instructedDispatch",
      requestParams
    );
    this.iDispatchResponseData = response.data ?? this.defaults.responseData;
    this.loading = false;
    this.dispatchSkeleton = false;
    this.iLoading = false;
    this.iDispatchSkeleton = false;
    this.drivers = await this.driverService.getAll();
  }
  async created(): Promise<void> {
    await this.initialize();
    this.checkIfAdmin();
  }
  checkIfAdmin(): void {
    if (this.userString != null) {
      const user = JSON.parse(this.userString);
      const userRoles = user.roles;
      if (userRoles.includes("admin")) {
        this.isAdmin = true;
      }
    }
  }
  visitRoute(link: string): void {
    this.$router.push(link);
  }
  async setDispatches(pageNumber: number): Promise<void> {
    this.dispatchSkeleton = true;
    let requestParams = this.dispatchResponseData.requestParams;
    requestParams.pageNumber = pageNumber;
    const response = await this.apiService.getAll("dispatch", requestParams);
    this.dispatchResponseData = response.data;
    this.dispatchSkeleton = false;
  }
  async setIDispatches(pageNumber: number): Promise<void> {
    this.iDispatchSkeleton = true;
    let requestParams = this.iDispatchResponseData.requestParams;
    requestParams.pageNumber = pageNumber;
    const response = await this.apiService.getAll("dispatch", requestParams);
    this.iDispatchResponseData = response.data;
    this.iDispatchSkeleton = false;
  }
  getSelectedCommodities(
    commodities: IDispatchedCommodity[]
  ): IInputCommodities[] {
    let selected: IInputCommodities[] = [];
    for (let commodity of commodities) {
      selected.push({
        commodity: commodity.commodity,
        unit: {
          id: 0,
          first: commodity.commodity.unit,
          second: commodity.commodity.unit,
          value: 1,
        },
        quantity: commodity.quantity,
        maxQuantity: commodity.quantity,
      });
    }
    return selected;
  }
  receiptModalId(i: number): { id: number; name: string } {
    return {
      id: i,
      name: "createReceiptModal" + i,
    };
  }
  iReceiptModalId(i: number): { id: number; name: string } {
    return {
      id: i,
      name: "createInstructedReceiptModal" + i,
    };
  }
  viewDispatchModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "viewDispatchModal" + i,
      showOverlay: false,
    };
  }
  closeModal(id: number): void {
    const modal = this.deleteDispatchModalId(id);
    this.$bvModal.hide(modal.name);
  }
  deleteDispatchModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "deleteDispatchModal" + i,
      showOverlay: false,
    };
  }
  editDispatchModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "editDispatchModal" + i,
      showOverlay: false,
    };
  }
  async submitNewReceipt(receipt: IReceipt): Promise<void> {
    this.receiptOverlay.show = true;
    const result = await this.receiptService.create(receipt);
    const modalName = this.receiptModalId(result.data.dispatchId).name;
    this.receiptOverlay.show = false;
    this.$bvModal.hide(modalName);
    let message = "Oh Snap! something went wrong. Please try again later.";
    let title = "Creating a new Receipt";
    let variant = "danger";
    if (result.isSuccess) {
      this.receipt = this.defaults.receipt;
      message = "New Receipt created successfully";
      variant = "success";
    }
    this.makeToast(message, title, variant);
    await this.initialize();
  }
  async submitNewInstructedReceipt(receipt: IInstructedReceipt): Promise<void> {
    this.iReceiptOverlay.show = true;
    const result = await this.iReceiptService.create(receipt);
    const modalName = this.iReceiptModalId(result.data.instructionId).name;
    this.iReceiptOverlay.show = false;
    this.$bvModal.hide(modalName);
    let message = "Oh Snap! something went wrong. Please try again later.";
    let title = "Creating a new Receipt";
    let variant = "danger";
    if (result.isSuccess) {
      this.iReceipt = this.defaults.instructedReceipt;
      message = "New Receipt created successfully";
      variant = "success";
    }
    this.makeToast(message, title, variant);
  }
  async updateDispatch(dispatch: IDispatch): Promise<void> {
    this.edit = { showOverlay: true };
    const result = await this.dispatchService.update(dispatch);
    let message = result.message;
    let title = "Updating Dispatch";
    let variant = "danger";
    if (result.isSuccess) {
      variant = "success";
    }
    this.edit = { showOverlay: false };
    const currentModal = this.editDispatchModalId(dispatch.id);
    this.$bvModal.hide(currentModal.name);
    this.makeToast(message, title, variant);
  }
  async deleteDispatch(id: number): Promise<void> {
    if (this.isAdmin) {
      this.confirmDelete.showOverlay = true;
      const result = await this.dispatchService.delete(id);
      this.confirmDelete.showOverlay = false;
      this.$bvModal.hide(this.deleteDispatchModalId(id).name);
      let message = result.message;
      let title = "Deleting Dispatch";
      let variant = "danger";
      if (result.isSuccess) {
        variant = "success";
      }
      this.makeToast(message, title, variant);
    } else {
      this.$bvModal.hide(this.deleteDispatchModalId(id).name);
      let message = "You can't update this resource!";
      let title = "Deleting Dispatch";
      let variant = "danger";
      this.makeToast(message, title, variant);
    }
  }
  makeToast(message = "", title = "", variant = ""): void {
    this.$bvToast.toast(message, {
      title: title,
      autoHideDelay: 5000,
      appendToast: true,
      variant: variant,
    });
  }
}
