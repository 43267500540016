
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { ITruckDriver } from "@/types/TruckDriver";
import { Defaults } from "@/helpers/Defaults";
import {IDispatch} from "@/types/Dispatch";
import {IReceipt} from "@/types/Receipt";

const ReceiptProps = Vue.extend({
  props: {
    modal: Object,
    dispatch: Object as PropType<IDispatch>,
    receipt: Object as PropType<IReceipt>,
    receiptOverlay: Object,
  },
});
@Component
export default class ReceiptModal extends ReceiptProps {
  driver: ITruckDriver = new Defaults().driver;
  drivers: ITruckDriver[] = [];
  save(): void {
    this.receipt.dispatch = this.dispatch;
    this.receipt.dispatchId = this.dispatch.id;
    this.$emit("save:receipt", this.receipt);
  }
}
