import axios from "axios";
import { IDispatch } from "@/types/Dispatch";
import { IServiceResponse } from "@/types/ServiceResponse";
import { Filters } from "@/helpers/Filters";
/*
 *Dispatch service
 * Provides UI business logic for dispatch
 */
export class DispatchService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IServiceResponse> {
    try {
      const inclStr =
        '/?includes=["Driver","LoadingPlan","LoadingPlan.Transporter","LoadingPlan.Warehouse","LoadingPlan.District","LoadingPlan.Commodity"]';
      const result = await axios.get(`${this.API_URL}/Dispatch${inclStr}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      //const dashboardFilters = new Filters();
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async create(dispatch: IDispatch): Promise<IServiceResponse> {
    try {
      const result = await axios.post(`${this.API_URL}/Dispatch`, dispatch, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async delete(id: number): Promise<IServiceResponse> {
    try {
      const result = await axios.get(`${this.API_URL}/Dispatch/delete/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async update(dispatch: IDispatch): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/Dispatch/update`,
        dispatch,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
