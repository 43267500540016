
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDispatch } from "@/types/Dispatch";
const DispatchProps = Vue.extend({
  props: {
    modal: Object,
    driver: Object,
    plan: Object,
    drivers: [],
    dispatch: Object,
    edit: Object,
  },
});
@Component({
  name: "DispatchModal",
  components: {},
})
export default class DispatchModal extends DispatchProps {
  truckNumberState = null;
  fdpState = null;
  licenceState = null;
  quantityState = null;
  dateState = null;
  initialQuantity = this.dispatch.quantity / 20;

  update(): void {
    this.$emit("update:dispatch", this.dispatch);
  }
  get maxQty(): number {
    return (this.plan.balance + this.initialQuantity) * 20;
  }
  get tonnage(): number {
    return +(this.dispatch.quantity / 20).toFixed(2);
  }
  get balance(): number {
    return (this.plan.balance + this.initialQuantity) - this.tonnage;
  }
  changeDriver(): void {
    const name = this.dispatch.driver.name;
    const allDrivers = this.drivers;
    this.dispatch.driverId = 0;
    this.dispatch.driver.id = 0;
    if (Array.isArray(allDrivers)) {
      const selectedDrivers = allDrivers.filter(function (selectedDriver) {
        if (selectedDriver.name == name) {
          return selectedDriver.licence;
        }
      });
      if (selectedDrivers.length > 0) this.dispatch.driver = selectedDrivers[0];
    }
  }
  changeLicence(): void {
    const licence = this.dispatch.driver.licence;
    const allDrivers = this.drivers;
    if (Array.isArray(allDrivers)) {
      const selectedDrivers = allDrivers.filter(function (selectedDriver) {
        if (selectedDriver.licence == licence) {
          return selectedDriver.licence;
        }
      });
      if (selectedDrivers.length > 0) this.dispatch.driver = selectedDrivers[0];
    }
  }
}
