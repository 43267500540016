
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { ITruckDriver } from "@/types/TruckDriver";
import { IUser } from "@/types/User";
import { Defaults } from "@/helpers/Defaults";
import { IDispatchedCommodity } from "@/types/DispatchedCommodity";
import { IUnit } from "@/types/Unit";
import { IInputCommodities } from "@/types/InputCommodities";
import { IReceivedCommodity } from "@/types/ReceivedCommodities";

const ReceiptProps = Vue.extend({
  props: {
    modal: Object,
    dispatch: Object,
    receipt: Object,
    users: Array as PropType<IUser[]>,
    commodities: Array as PropType<IDispatchedCommodity[]>,
    selected: Array as PropType<IInputCommodities[]>,
    units: Array as PropType<IUnit[]>,
    receiptOverlay: Object,
  },
});
@Component
export default class InstructedReceiptModal extends ReceiptProps {
  defaults = new Defaults();
  driver: ITruckDriver = new Defaults().driver;
  drivers: ITruckDriver[] = [];
  units: IUnit[] = [];
  get quantities(): number[] {
    let quantities: number[] = [];
    for (let item of this.selected) {
      quantities.push(item.quantity * item.unit.value);
    }
    return quantities;
  }
  get maxQuantities(): number[] {
    let quantities: number[] = [];
    for (let item of this.selected) {
      quantities.push(item.maxQuantity * item.unit.value);
    }
    return quantities;
  }
  save() {
    this.receipt.instructedDispatch = this.dispatch;
    this.receipt.instructedDispatchId = this.dispatch.id;
    let reliefItems = this.selected;
    let receivedCommodities: IReceivedCommodity[] = [];
    for (let item of reliefItems) {
      let newQty = item.quantity * item.unit.value;
      receivedCommodities.push({
        commodity: item.commodity,
        commodityId: item.commodity.id,
        id: 0,
        instructedReceipt: this.defaults.instructedReceipt,
        instructedReceiptId: this.receipt.id,
        quantity: newQty <= item.maxQuantity ? newQty : item.maxQuantity,
      });
      this.receipt.receivedCommodities = receivedCommodities;
      this.$emit("save:receipt", this.receipt);
    }
  }
  changeUnit(index: number) {
    let selected = this.selected[index];
    selected.quantity *= selected.unit.value;
    //selected.maxQuantity *= selected.unit.value;
    this.selected[index] = selected;
    console.log(this.selected[index]);
  }
  selectedUnits(unit: IUnit): IUnit[] {
    let selected = [];
    selected.push(unit);
    selected = selected.concat(
      this.units.filter(
        (item) => item.first.toLowerCase() == unit.second.toLowerCase()
      )
    );
    return selected;
  }
  changeQty(index: number) {
    console.log(this.selected[index]);
  }
}
