import axios from "axios";
import { IInstructedReceipt } from "@/types/InstructedReceipt";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *InstructedReceipt service
 * Provides UI business logic for instructed Receipt
 */
export class InstructedReceiptService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IServiceResponse> {
    try {
      const inclStr =
        '/?includes=["InstructedDispatch","InstructedDispatch.Driver","InstructedDispatch.Instruction",' +
        '"InstructedDispatch.Instruction.District","InstructedDispatch.Instruction.Warehouse",' +
        '"InstructedDispatch.Dispatcher","ReceivedCommodities","ReceivedCommodities.Commodity"]';
      const result = await axios.get(
        `${this.API_URL}/InstructedReceipt${inclStr}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      //const dashboardFilters = new Filters();
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async create(
    instructedReceipt: IInstructedReceipt
  ): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/InstructedReceipt`,
        instructedReceipt,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      console.log(error);
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async delete(id: number): Promise<IServiceResponse> {
    try {
      const result = await axios.get(
        `${this.API_URL}/InstructedReceipt/delete/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async update(
    instructedReceipt: IInstructedReceipt
  ): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/InstructedReceipt/update`,
        instructedReceipt,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
